import * as React from 'react';
import classNames from 'classnames';
import { getSize } from './utils';
const Steps = (props) => {
    const { size, steps, rounding: customRounding = Math.round, percent = 0, strokeWidth = 8, strokeColor, trailColor = null, prefixCls, children, } = props;
    const current = customRounding(steps * (percent / 100));
    const stepWidth = size === 'small' ? 2 : 14;
    const mergedSize = size !== null && size !== void 0 ? size : [stepWidth, strokeWidth];
    const [width, height] = getSize(mergedSize, 'step', { steps, strokeWidth });
    const unitWidth = width / steps;
    const styledSteps = new Array(steps);
    for (let i = 0; i < steps; i++) {
        const color = Array.isArray(strokeColor) ? strokeColor[i] : strokeColor;
        styledSteps[i] = (React.createElement("div", { key: i, className: classNames(`${prefixCls}-steps-item`, {
                [`${prefixCls}-steps-item-active`]: i <= current - 1,
            }), style: {
                backgroundColor: i <= current - 1 ? color : trailColor,
                width: unitWidth,
                height,
            } }));
    }
    return (React.createElement("div", { className: `${prefixCls}-steps-outer` },
        styledSteps,
        children));
};
export default Steps;
