var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Children, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import { useComposeRef } from 'rc-util/lib/ref';
import { devUseWarning } from '../_util/warning';
import Wave from '../_util/wave';
import { useComponentConfig } from '../config-provider/context';
import DisabledContext from '../config-provider/DisabledContext';
import useSize from '../config-provider/hooks/useSize';
import { useCompactItemContext } from '../space/Compact';
import Group, { GroupSizeContext } from './button-group';
import { isTwoCNChar, isUnBorderedButtonVariant, spaceChildren } from './buttonHelpers';
import DefaultLoadingIcon from './DefaultLoadingIcon';
import IconWrapper from './IconWrapper';
import useStyle from './style';
import Compact from './style/compact';
function getLoadingConfig(loading) {
    if (typeof loading === 'object' && loading) {
        let delay = loading === null || loading === void 0 ? void 0 : loading.delay;
        delay = !Number.isNaN(delay) && typeof delay === 'number' ? delay : 0;
        return {
            loading: delay <= 0,
            delay,
        };
    }
    return {
        loading: !!loading,
        delay: 0,
    };
}
const ButtonTypeMap = {
    default: ['default', 'outlined'],
    primary: ['primary', 'solid'],
    dashed: ['default', 'dashed'],
    link: ['primary', 'link'],
    text: ['default', 'text'],
};
const InternalCompoundedButton = React.forwardRef((props, ref) => {
    var _a, _b;
    const { loading = false, prefixCls: customizePrefixCls, color, variant, type, danger = false, shape = 'default', size: customizeSize, styles, disabled: customDisabled, className, rootClassName, children, icon, iconPosition = 'start', ghost = false, block = false, 
    // React does not recognize the `htmlType` prop on a DOM element. Here we pick it out of `rest`.
    htmlType = 'button', classNames: customClassNames, style: customStyle = {}, autoInsertSpace, autoFocus } = props, rest = __rest(props, ["loading", "prefixCls", "color", "variant", "type", "danger", "shape", "size", "styles", "disabled", "className", "rootClassName", "children", "icon", "iconPosition", "ghost", "block", "htmlType", "classNames", "style", "autoInsertSpace", "autoFocus"]);
    // https://github.com/ant-design/ant-design/issues/47605
    // Compatible with original `type` behavior
    const mergedType = type || 'default';
    const [mergedColor, mergedVariant] = useMemo(() => {
        if (color && variant) {
            return [color, variant];
        }
        const colorVariantPair = ButtonTypeMap[mergedType] || [];
        if (danger) {
            return ['danger', colorVariantPair[1]];
        }
        return colorVariantPair;
    }, [type, color, variant, danger]);
    const isDanger = mergedColor === 'danger';
    const mergedColorText = isDanger ? 'dangerous' : mergedColor;
    const { getPrefixCls, direction, autoInsertSpace: contextAutoInsertSpace, className: contextClassName, style: contextStyle, classNames: contextClassNames, styles: contextStyles, } = useComponentConfig('button');
    const mergedInsertSpace = (_a = autoInsertSpace !== null && autoInsertSpace !== void 0 ? autoInsertSpace : contextAutoInsertSpace) !== null && _a !== void 0 ? _a : true;
    const prefixCls = getPrefixCls('btn', customizePrefixCls);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls);
    const disabled = useContext(DisabledContext);
    const mergedDisabled = customDisabled !== null && customDisabled !== void 0 ? customDisabled : disabled;
    const groupSize = useContext(GroupSizeContext);
    const loadingOrDelay = useMemo(() => getLoadingConfig(loading), [loading]);
    const [innerLoading, setLoading] = useState(loadingOrDelay.loading);
    const [hasTwoCNChar, setHasTwoCNChar] = useState(false);
    const buttonRef = useRef(null);
    const mergedRef = useComposeRef(ref, buttonRef);
    const needInserted = Children.count(children) === 1 && !icon && !isUnBorderedButtonVariant(mergedVariant);
    // ========================= Mount ==========================
    // Record for mount status.
    // This will help to no to show the animation of loading on the first mount.
    const isMountRef = useRef(true);
    React.useEffect(() => {
        isMountRef.current = false;
        return () => {
            isMountRef.current = true;
        };
    }, []);
    // ========================= Effect =========================
    // Loading
    useEffect(() => {
        let delayTimer = null;
        if (loadingOrDelay.delay > 0) {
            delayTimer = setTimeout(() => {
                delayTimer = null;
                setLoading(true);
            }, loadingOrDelay.delay);
        }
        else {
            setLoading(loadingOrDelay.loading);
        }
        function cleanupTimer() {
            if (delayTimer) {
                clearTimeout(delayTimer);
                delayTimer = null;
            }
        }
        return cleanupTimer;
    }, [loadingOrDelay]);
    // Two chinese characters check
    useEffect(() => {
        // FIXME: for HOC usage like <FormatMessage />
        if (!buttonRef.current || !mergedInsertSpace) {
            return;
        }
        const buttonText = buttonRef.current.textContent || '';
        if (needInserted && isTwoCNChar(buttonText)) {
            if (!hasTwoCNChar) {
                setHasTwoCNChar(true);
            }
        }
        else if (hasTwoCNChar) {
            setHasTwoCNChar(false);
        }
    });
    // Auto focus
    useEffect(() => {
        if (autoFocus && buttonRef.current) {
            buttonRef.current.focus();
        }
    }, []);
    // ========================= Events =========================
    const handleClick = React.useCallback((e) => {
        var _a;
        // FIXME: https://github.com/ant-design/ant-design/issues/30207
        if (innerLoading || mergedDisabled) {
            e.preventDefault();
            return;
        }
        (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, 'href' in props
            ? e
            : e);
    }, [props.onClick, innerLoading, mergedDisabled]);
    // ========================== Warn ==========================
    if (process.env.NODE_ENV !== 'production') {
        const warning = devUseWarning('Button');
        warning(!(typeof icon === 'string' && icon.length > 2), 'breaking', `\`icon\` is using ReactNode instead of string naming in v4. Please check \`${icon}\` at https://ant.design/components/icon`);
        warning(!(ghost && isUnBorderedButtonVariant(mergedVariant)), 'usage', "`link` or `text` button can't be a `ghost` button.");
    }
    // ========================== Size ==========================
    const { compactSize, compactItemClassnames } = useCompactItemContext(prefixCls, direction);
    const sizeClassNameMap = { large: 'lg', small: 'sm', middle: undefined };
    const sizeFullName = useSize((ctxSize) => { var _a, _b; return (_b = (_a = customizeSize !== null && customizeSize !== void 0 ? customizeSize : compactSize) !== null && _a !== void 0 ? _a : groupSize) !== null && _b !== void 0 ? _b : ctxSize; });
    const sizeCls = sizeFullName ? ((_b = sizeClassNameMap[sizeFullName]) !== null && _b !== void 0 ? _b : '') : '';
    const iconType = innerLoading ? 'loading' : icon;
    const linkButtonRestProps = omit(rest, ['navigate']);
    // ========================= Render =========================
    const classes = classNames(prefixCls, hashId, cssVarCls, {
        [`${prefixCls}-${shape}`]: shape !== 'default' && shape,
        // line(253 - 254): Compatible with versions earlier than 5.21.0
        [`${prefixCls}-${mergedType}`]: mergedType,
        [`${prefixCls}-dangerous`]: danger,
        [`${prefixCls}-color-${mergedColorText}`]: mergedColorText,
        [`${prefixCls}-variant-${mergedVariant}`]: mergedVariant,
        [`${prefixCls}-${sizeCls}`]: sizeCls,
        [`${prefixCls}-icon-only`]: !children && children !== 0 && !!iconType,
        [`${prefixCls}-background-ghost`]: ghost && !isUnBorderedButtonVariant(mergedVariant),
        [`${prefixCls}-loading`]: innerLoading,
        [`${prefixCls}-two-chinese-chars`]: hasTwoCNChar && mergedInsertSpace && !innerLoading,
        [`${prefixCls}-block`]: block,
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-icon-end`]: iconPosition === 'end',
    }, compactItemClassnames, className, rootClassName, contextClassName);
    const fullStyle = Object.assign(Object.assign({}, contextStyle), customStyle);
    const iconClasses = classNames(customClassNames === null || customClassNames === void 0 ? void 0 : customClassNames.icon, contextClassNames.icon);
    const iconStyle = Object.assign(Object.assign({}, ((styles === null || styles === void 0 ? void 0 : styles.icon) || {})), (contextStyles.icon || {}));
    const iconNode = icon && !innerLoading ? (React.createElement(IconWrapper, { prefixCls: prefixCls, className: iconClasses, style: iconStyle }, icon)) : loading && typeof loading === 'object' && loading.icon ? (React.createElement(IconWrapper, { prefixCls: prefixCls, className: iconClasses, style: iconStyle }, loading.icon)) : (React.createElement(DefaultLoadingIcon, { existIcon: !!icon, prefixCls: prefixCls, loading: innerLoading, mount: isMountRef.current }));
    const kids = children || children === 0 ? spaceChildren(children, needInserted && mergedInsertSpace) : null;
    if (linkButtonRestProps.href !== undefined) {
        return wrapCSSVar(React.createElement("a", Object.assign({}, linkButtonRestProps, { className: classNames(classes, {
                [`${prefixCls}-disabled`]: mergedDisabled,
            }), href: mergedDisabled ? undefined : linkButtonRestProps.href, style: fullStyle, onClick: handleClick, ref: mergedRef, tabIndex: mergedDisabled ? -1 : 0 }),
            iconNode,
            kids));
    }
    let buttonNode = (React.createElement("button", Object.assign({}, rest, { type: htmlType, className: classes, style: fullStyle, onClick: handleClick, disabled: mergedDisabled, ref: mergedRef }),
        iconNode,
        kids,
        compactItemClassnames && React.createElement(Compact, { prefixCls: prefixCls })));
    if (!isUnBorderedButtonVariant(mergedVariant)) {
        buttonNode = (React.createElement(Wave, { component: "Button", disabled: innerLoading }, buttonNode));
    }
    return wrapCSSVar(buttonNode);
});
const Button = InternalCompoundedButton;
Button.Group = Group;
Button.__ANT_BUTTON = true;
if (process.env.NODE_ENV !== 'production') {
    Button.displayName = 'Button';
}
export default Button;
